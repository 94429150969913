/* ブログ全体のスタイル */
.blog-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;
}

/* ブログ一覧のスタイル */
.blog-list {
    list-style: none;
    padding: 0;
  }
  
  .blog-item {
    list-style-type: none;
    margin: 1em 0;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  
  .page-button {
    background-color: #f0f0f0;
    border: 1px solid #ffffffff;
    color: #333;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .page-button:hover {
    background-color: #ddd;
  }
  
  .page-button.active {
    background-color: #333;
    color: #fff;
  }

  .category-info {
    display: flex;
    margin-bottom: 20px;
  }
  
  .category-image {
    flex: 1;
    margin-right: 20px;
  }
  
  .category-image img {
    max-width: 100%;
    height: auto;
  }
  
  .category-description {
    flex: 2;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    border: 2px solid #ffffff;
    overflow: hidden;
    transition: border 0.2s ease, background-color 0.5s ease;
  }
  
  .card:hover {
    border: 2px solid #3DC9EC;
    background-color: #f2fcff;
  }
  
  .card-main {
    display: flex;
  }
  
  .card-image {
    width: auto;
    height: 150px;
    object-fit: cover;
  }
  
  .card-placeholder {
    background-color: #f0f0f0;
    color: #888;
    width: auto;
    min-width: 285.7px;
    height: 150px;
    text-align: center;
    padding: 20px;
    font-size: 14px;
  }
  
  .card-content {
    width: 50%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .card-title {
    margin: 0;
    font-size: 1.25em;
  }
  
  .card-title a {
    text-decoration: none;
    color: #333;
  }
  
  .card-title a:hover {
    text-decoration: underline;
  }
  
  .card-timestamp {
    margin-top: 0.5em;
    font-size: 0.875em;
    color: #999;
  }
  
  .card-description-container {
    padding: 1em;
  }
  
  .card-description {
    margin: 0;
    white-space: pre-wrap;
  }

  .card-no-description {
    margin: 0;
    color: #888;
    font-style: italic;
    white-space: pre-wrap;
  }
  
  .card-divider {
    border: none;
    border-top: 1px solid #ddd;
    margin: 0;
  }

/* 個別ブログのスタイル */
.blog-detail {
    margin-bottom: 50px;
}

.blog-detail h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.blog-detail img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-detail .content {
    line-height: 1.6;
}

.category-nav {
    list-style: none;
    padding: 0;
    display: flex;
}

.category-nav li {
    margin-right: 20px;
}

.category-nav li a {
    text-decoration: none;
    color: #333;
}

.blog-header .logo-container {
    display: flex;
    justify-content: left;
    padding: 0;
}

.blog-header .nav-menu {
    display: flex;
    justify-content: left;
    padding: 5px 0;
}

.blog-header .logo-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.blog-header .nav-menu {
    width: 100%;
    display: flex;
    justify-content: left;
    padding: 5px 0;
}

.blog-container {
    padding-bottom: rem;
  }
  
  .blog-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .blog-item {
    margin-bottom: 20px;
  }
  
  .no-results {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #666;
  }

  @media screen and (min-width: 769px) {
    .blog-header .container {
        flex-direction: column;
        align-items: center;
    }

    .blog-header .logo-container {
        padding: 5px 0;
    }

    .blog-header .nav-menu {
        padding: 0;
    }

    .blog-header nav ul {
        flex-direction: row;
        justify-content: center;
    }

    .blog-header nav li {
        margin: 0 30px 0 0;
    }
}

@media screen and (max-width: 768px) {
    .blog-container {
        width: 90%;
        padding-top: 0;
    }

    .nav-menu .search-container {
        margin-bottom: 20px;
    }

    .card-main {
        flex-direction: column;
    }

    .card-image {
        width: 100%;
        height: auto;
    }

    .card-content {
        width: 100%;
    }
}