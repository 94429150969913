/* 基本設定 */
body {
    font-family: sans-serif;
    margin: 0;
}

.container {
    width: 80%;
    margin: 0 auto;
    padding: 5px 0;
}

a {
    text-decoration: none;
    color: #333;
}

/* ヘッダー */
header .logo {
    max-width: 150px;
    max-height: 70px; 
    display: block;
    width: 100%; 
}

header {
    background-color: #f0f0f0;
    padding: 5px 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* オプション：シャドウを追加 */
    top: 0;
}

header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

header nav li {
    margin-left: 20px;
}

header nav li a {
    position: relative;
    font-size: 15px;
}

header nav li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #3DC9EC; 
    transition: width .3s ease;
    -webkit-transition: width .3s ease;
}

header nav li a:hover::after {
    width: 100%;
    left: 0;
    background: #3DC9EC; 
}

header .search-input {
    width: 60%;
    margin-left: 10px;
    padding: 10px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 3px 0 0 3px;
    height: 30px;
}

header .search-button {
    padding: 0 10px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-left: none;
    background-color: #333;
    color: white;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    height: 30px;
}

header .search-button:hover {
    background-color: #555;
}

.nav-menu {
    display: flex;
}

.hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
}

/* メインビジュアル */
#hero {
    position: relative;
}

#hero-image {
    width: 100%;
    height: 300px;
    background-image: url("../images/home-background-image2.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#hero-logo {
    width: 100px;
    margin-bottom: 20px;
}

.hero-text {
    color: #041926;
}

/* 各コンテンツセクション */
#service,
#about,
#news,
#contact {
    padding: 50px 0;
}

#service {
    background-color: #f8f8f8;
}

#news {
    background-color: #eee;
}

/*ページ切り替え効果*/
.fade-enter {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit-active {
    opacity: 0;
}

/* フッター */
footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
}

/* スマホ対応 */
@media screen and (max-width: 768px) {
    .container {
        width: 90%;
    }

    header .container {
        flex-direction: row;
        justify-content: space-between;
    }

    .nav-menu {
        display: flex; /* デフォルトで非表示にしない */
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px; /* ヘッダーの高さ */
        left: 0;
        background-color: #f0f0f0;
        padding: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        pointer-events: none; /* クリックイベントを無効にする */
    }
    
    .nav-menu.open {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto; /* クリックイベントを有効にする */
    }
    .hamburger {
        display: block;
    }

    header nav ul {
        flex-direction: column;
        width: 100%;
    }

    header nav li {
        margin: 10px 0;
        text-align: center;
    }

    header form{
        display: flex;
        align-items: center;
    }

    header .search-input {
        width: 60%;
        margin-left: 10px;
        font-size: 10px;
    }
    
    header .search-button {
        padding: 0 5px;
        font-size: 10px;
    }
}

@media screen and (min-width: 769px) {
    .blog-header .container {
        flex-direction: column;
        align-items: center;
    }

    .blog-header .logo-container {
        padding: 5px 0;
        margin-bottom: 10px;
    }

    .blog-header .nav-menu {
        padding: 0;
    }

    .blog-header nav ul {
        flex-direction: row;
        justify-content: center;
    }

    .blog-header nav li {
        margin: 0 20px;
    }
}