#contactForm {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: none;
    border-radius: 8px;
    background-color: #f8f8f8;
    margin: 2rem auto;
}

#contactForm label {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
}

#contactForm input,
#contactForm textarea {
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
}

#contactForm input:focus,
#contactForm textarea:focus {
    border-color: #333;
    outline: none;
}

#contactForm button {
    padding: 12px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

#contactForm button:hover {
    background-color: #555;
}

/* 横並びのスタイル */
.horizontal-group {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .horizontal-group {
        flex-direction: column;
    }
}
