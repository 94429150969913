*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* 基本設定 */
body {
    font-family: sans-serif;
    margin: 0;
}

main {
    padding-top: 60px;
    min-height:calc(100vh - 163px);
}

.container {
    width: 80%;
    margin: 0 auto;
    padding: 5px 0;
}

h1,h2,h3,h4,h5,h6 {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

a {
    text-decoration: none;
    color: #333;
}

/* メインビジュアル */
#hero {
    position: relative;
}

#hero-image {
    width: 100%;
    height: 400px;
    background-image: url("../images/home-background-image2.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#hero-logo {
    margin-bottom: 20px;
}
img.hero-logo {
    max-width: 90%;
    height: auto;
}

.hero-text {
    color: #041926;
}

/* 各コンテンツセクション */
#service,
#about,
#news {
    padding: 50px 0;
}

#service {
    background-color: #f8f8f8;
}

#news {
    background-color: #eee;
}

/* 詳しくはこちらボタン */
.detail-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 12px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.detail-button:hover {
    background-color: #222;
}

.fade-enter {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.fade-exit-active {
    opacity: 0;
}

.thank-you-details div:nth-child(odd) {
    background-color: #f8f8f8;
    padding: 10px;
}

.thank-you-details div:nth-child(even) {
    background-color: #eee;
    padding: 10px;
}

/* フッター */
/* フッター */
footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sitemap {
    margin-bottom: 10px;
}

.sitemap ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* 横並びかつ折り返し */
    justify-content: center; /* 中央寄せ */
}

.sitemap ul li {
    margin: 5px 15px; /* 余白を調整 */
}

.sitemap ul li a {
    color: #fff;
    text-decoration: none;
}

.sitemap ul li a:hover {
    text-decoration: underline;
}

.footer-bottom {
    border-top: 1px solid #444;
    padding-top: 10px;
    width: 100%;
    text-align: center;
}

/* スマホ対応 */
@media screen and (max-width: 768px) {
    .container {
        width: 90%;
    }
    
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .sitemap ul {
        text-align: center;
    }
}
